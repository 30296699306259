body {
    font-family: Arial, sans-serif;
}

.top-bar {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background-color: #f8f8f8;
}

.overlay {
    position: fixed; /* Fixes the overlay on the screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensures it is above other content */
  }
  
  .overlay-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 90%; /* Adjusts the width of the content */
    max-height: 90%; /* Limits height to avoid overflow */
    overflow-y: auto; /* Ensures large content scrolls */
  }  

.selected-product {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s ease;
}

.selected-product:hover {
    background-color: #f5f5f5;
}

.edit-button,
.delete-button,
.save-button {
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.edit-button {
    margin-left: 10px;
    background-color: green;
    color: white;
    border: none;
}

.edit-button:hover {
    background-color: darkgreen;
}

.delete-button {
    background-color: red;
    color: white;
    border: none;
    margin-left: 10px;
}

.delete-button:hover {
    background-color: darkred;
}

.save-button {
    background-color: green;
    color: white;
    border: none;
    padding: 10px;
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.save-button:hover {
    background-color: #228b22; /* Slightly lighter green for hover effect */
}

/* Active states for all buttons to add a slight press-down effect */
.edit-button:active,
.delete-button:active,
.save-button:active {
    transform: scale(0.98);
}

#products-list {
    margin-top: 20px;
}

.color-display {
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer; /* Suggests interactivity */
}

.color-display:hover {
    border-color: #888;
}

.product-selection-overlay {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure it appears above other elements */
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto; /* Ensures responsiveness */
}

/* Responsive styles */
@media (max-width: 768px) {
    .overlay-content {
        width: 100%;
        padding: 10px;
    }

    .selected-product {
        flex-direction: column;
        align-items: flex-start;
    }

    .edit-button,
    .delete-button,
    .save-button {
        margin-top: 10px;
        width: 100%;
    }
}
