.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
        font-size: 25px;
    }

    .body {
        width: 70%;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        gap: 20px;

        .bottom {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            font-size: 16px;
            width: 100%;
            margin-top: 30px;

            .seperator {
                height: 2px;
                width: 100%;
                background: rgba(242, 242, 242, 1);
            }

            .footer {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }



        .left {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            width: 100%;
            background: rgba(242, 242, 242, 1);
            padding: 20px;
            border-radius: 5px;
            font-size: 16px;

            .head {
                font-weight: 600;
                color: rgba(18, 18, 18, 1);
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            background: rgba(229, 103, 141, 1);
            gap: 10px;
            border-radius: 10px;
            color: white;
            font-size: 18px;
            padding: 30px;

            button {
                color: rgba(252, 39, 121, 1);
                padding: 10px;
                border: none;
                width: 300px;
                border-radius: 5px;
                background: rgba(255, 255, 255, 1);
                font-size: 16px;
                font-weight: 600;
            }

            .head {
                font-size: 20px;
                font-weight: 600;
            }

        }
    }

}