.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.faqContainer {
    display: flex;
    justify-content: space-between;

    .section1 {
        width: 35%;
    }

    .section2 {
        width: 60%;
    }
}
.faqs{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
}

@media (max-width : 760px) {
    .faqContainer {
        flex-direction: column;
        gap: 30px;

        .section1 {
            width: 100%;
        }

        .section2 {
            width: 100%;
        }
    }
    .faqs{
        margin: 0;
    }
}