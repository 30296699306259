.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
        font-size: 25px;
    }

    .body {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        gap: 20px;



        .left {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            width: 60%;

            .head {
                font-weight: 600;
                font-size: 20px;
                color: rgba(18, 18, 18, 1);
            }

            .bottom {
                display: flex;
                flex-direction: column;
                gap: 10px;
                // margin-top: 60px;

                button {
                    background: rgba(252, 39, 121, 1);
                    padding: 10px;
                    border: none;
                    width: 300px;
                    border-radius: 5px;
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    font-weight: 600;
                }

                span {
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            video {
                width: 100%;
                height: 300px;
                object-fit: cover;
                border-radius: 10px;
            }

            img {
                width: 100%;
                height: 300px;
                // object-fit: cover;
                border-radius: 10px;
            }

        }
    }

}