
.middle {
    width: 100%;
    display: flex;
    gap: 30px;
    padding: 30px;
    justify-content: space-between;
    height: 100%;
    overflow: scroll;
    // align-items: center;

    .left {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .first {
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            // flex-direction: column;
            border-radius: 10px;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
            padding: 10px;
            max-width: 500px;
            width: 500px;

            &.active {
                border: 1px solid rgba(207, 207, 207, 1);
                background: rgba(249, 249, 249, 1);
            }

            .elems {
                display: flex;
                flex-direction: column;
                max-height: 400px;
                overflow: scroll;
                padding: 0;

                .elewrapper {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    :hover {
                        background: rgba(247, 247, 247, 1);
                    }

                    img {
                        width: 80px;
                        height: 60px;
                        border-radius: 5px;
                    }

                    .elem {
                        cursor: pointer;

                        &.mainelem {
                            font-weight: 600;
                        }

                        :hover {
                            background: gray;
                        }
                    }
                }
            }

            .top {
                display: flex;
                flex-direction: column;
                font-size: 14px;

                .head {
                    font-size: 16px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    img {
                        height: 20px;
                        width: 20px;
                    }
                }
            }

            .text {
                width: 100%;
            }

            .vtobtn {
                white-space: nowrap;
                width: fit-content;
                overflow: hidden;
                text-overflow: ellipsis;
                background: rgba(252, 39, 121, 1);
                padding: 5px 10px;
                border-radius: 5px;
                border: none;
                color: white;
                font-size: 14px;

                &.disable {
                    opacity: 0.4;
                    cursor: not-allowed;
                }
            }
        }
    }

    .right {
        // position: relative;
        display: flex;
        // align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        video {
            width: 400px;
            height: 75%;
            object-fit: cover;
            border-radius: 10px;
        }

        img {
            width: 400px;
            height: 75%;
            object-fit: cover;
            border-radius: 10px;
        }

    }


    .block {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 30px;

        .head {
            font-weight: 800;
        }

        .body {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}

.dropdownbtn {
    border: 1px solid rgba(107, 107, 107, 1);
    padding: 10px;
    width: 200px;
    font-size: 14px;
    color: rgba(87, 87, 87, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    background: white;

    &.active {
        border: 1px solid rgba(252, 39, 121, 0.5);
    }
}