.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.headerRow {
    display: flex;
    justify-content: space-between;

    .addprdtbtn {
        background: rgba(252, 39, 121, 1);
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: 10px;
        gap: 5px;
        border-radius: 5px;
        color: white;
        border: none;

        &.disabled {
            opacity: 0.4;
            cursor: not-allowed;
            pointer-events: none;
        }

        img {
            height: 20px;
            width: 20px;
        }
    }

    .infowrapper {
        display: flex;
        gap: 10px;
        color: rgba(87, 87, 87, 1);
        font-size: 14px;
        background: rgba(255, 251, 232, 1);
        border: 1px solid rgba(234, 221, 188, 1);
        border-radius: 6px;
        align-items: center;
        padding: 5px;

        &.hidden{
            visibility: hidden;
        }

        img {
            width: 15px;
            height: 15px;
        }
    }
}

.colorfield {
    .Polaris-TextField__Backdrop {
        border: none;
    }
}