.wrapper{
    .dropdownwrapper{
        .temp{
            div{
                width: 100%;
            }
            span{
                font-size: 0.8125rem;
                color: rgb(48,48,48);
            }
            .elems {
                display: flex;
                flex-direction: column;
                max-height: 400px;
                overflow: scroll;
                padding: 0;
            
                .elewrapper {
                    display: flex;
                    align-items: center;
                    gap: 10px;
            
                    :hover {
                        background: rgba(247, 247, 247, 1);
                    }
            
                    img {
                        width: 80px;
                        height: 60px;
                        border-radius: 5px;
                    }
            
                    .elem {
                        cursor: pointer;
            
                        &.mainelem {
                            font-weight: 600;
                        }
            
                        :hover {
                            background: gray;
                        }
                    }
                }
            }

            .dropdownbtn {
                border: 1px solid rgba(107, 107, 107, 1);
                padding: 10px;
                width: 100%;
                font-size: 14px;
                color: rgba(87, 87, 87, 0.7);
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 5px;
                background: white;
            
                &.active {
                    border: 1px solid rgba(252, 39, 121, 0.5);
                }
            }
        }
    }
}
