.container{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.subscriptionsContainer{
    display: flex;
    justify-content: space-between;
}

.subscriptions{
    width: 24%;
    height: 100%;
}

@media (max-width : 1000px) {
    .subscriptions{
       width: 100%;
    }
    .subscriptionsContainer{
flex-direction: column;
gap: 30px;
    }
}