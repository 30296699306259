.spacing{
    padding: 0 50px;
    margin-top: 20px;
}

@media (max-width : 760px) {
    .spacing{
        padding: 0 10px;
        margin-top: 10px;
    }
}