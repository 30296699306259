/* .dropzone {
  border: 2px dashed #007bff !important;
  background-color: #f8f9fa;
  color: #6c757d;
  padding: 20px !important;
  text-align: center !important;
  border-radius: 5px;
  cursor: pointer;
}

.dropzone:hover {
  background-color: #e9ecef;
}

.dropzone p {
  margin: 0;
  font-size: 1.2rem;
}

.file-list {
  margin-top: 10px;
}

.file-item {
  padding: 5px 0;
  border-bottom: 1px solid #e9ecef;
} */
/* ClientForm.css */
.dropzone {
  border: 2px dashed #007bff !important;
  border-radius: 5px;
  padding: 20px !important;
  text-align: center !important;
  cursor: pointer;
  margin-bottom: 20px;
  background-color: #f8f9fa;
}

.dropzone p {
  margin: 0;
  color: #007bff;
}

.form-control-color {
  width: 100%;
  height: 40px;
  padding: 0;
}

.form-group {
  margin-bottom: 1.5rem;
}

.container {
  max-width: 900px;
}

.file-list {
  margin-top: 10px;
}

.file-item {
  padding: 5px 0;
  border-bottom: 1px solid #e9ecef;
}
