.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.row1 {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .section1 {
    width: 67%;
    height: 100%;
    flex-direction: column;
  }

  .section2 {
    width: 31%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .rowa {
      height: 50%;
    }
    .rowb {
      height: 50%;
    }
  }

  //   .section3 {
  //     width: 31%;
  //     height: 50%;
  //     flex-direction: row;
  //   }
}

@media (max-width: 760px) {
  .row1 {
    flex-direction: column;
    gap: 30px;
    .section1 {
      width: 100%;
    }

    .section2 {
      width: 100%;
    }
  }
}
