

.cardwrapper{
    --component-outline-color: lightgrey;
    --background-color: white;
    background-color: var(--background-color);
    border: 1px solid var(--component-outline-color);
    border-radius: 12px;
    cursor: default;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    transition: border .1s linear;
    width: 100%;

    .header{
        border-bottom: 1px solid var(--component-outline-color);
        color: inherit;
        min-height: 64px;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: background-color .25s ease;

        span{
            font-size: 19px;
            font-weight: 700;
            height: 30px;
        }
    }

    .loader{
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
    }

    .error{
        position: absolute;
        height: 100%;
        width: 100%;
        background: red;
        opacity: 0.4;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
    }

    .body{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 250px;
        justify-content: center;
        font-size: 60px;
        font-weight: 700;
        padding: 0 20px;
    }
}